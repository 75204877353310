.arrow{
    position: relative;


    &::after{
        color: $link;
        font-family: $icon-font;
        transition: all .2s;
        font-weight: 100;
        padding-left: var(--spacer-sm);
        font-size: var(--font-size-sm);
        content: '\f061';
        position: absolute;
        top: 2px;

        @include at(lg){
            top: 4px;
            font-size: var(--font-size);
        }
    }

}