.card {
	$b: &;

	background-color: white;
	display: grid;
	grid-template-columns: 25% 75%;
	box-shadow: 0px 3px 11px 0px $grey-light;
	border-radius: 4px;
	border: 0.1px solid $grey-light;
	flex: 0 0 auto;
	width: 100%;
	position: relative;

	@include at(md) {
		width: 48%;
		margin: 0;
	}

	@include at(lg) {
		width: 32%;
	}

	.arrow {
		transition-property: transform;
		transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
		transition-duration: 150ms;

		&::after {
			@include at(lg) {
				font-size: var(--font-size-sm);
			}
		}
	}

	&:hover {
		.arrow {
			transform: translateX(0.25em);
		}

		#{$b}__ctaText {
			color: $blue-darker;
		}
	}

	&.-accent {
		display: block;
		border-left: 5px solid $turquoise;

		&.-login {
			border-left: 8px solid $blue;
		}
	}

	&__imageWrapper {
		position: relative;
	}

	&__image {
		width: 100%;
		height: 100%;
		object-fit: cover;
		position: absolute;
		top: 0;
		left: 0;
	}

	&__contentWrapper {
		padding: 16px 20px;
		display: flex;
		flex-direction: column;
	}

	&__title {
		font-size: var(--font-size);
		color: $black;
	}

	&__meta {
		font-size: var(--font-size-sm);
		color: $grey;
		margin: 0.5em 0;
		margin-top: auto;
	}

	&__cta {
		display: flex;
		margin-top: 0;
	}

	&__ctaText {
		font-size: var(--font-size-sm);
		color: $black;
		font-weight: normal;
	}

	&__ctaLink {
		font-size: var(--font-size-sm);
		color: $black;
		font-weight: normal;

		&:hover {
			text-decoration: none;
		}

		&::before {
			position: absolute;
			top: 0px;
			right: 0px;
			bottom: 0px;
			left: 0px;
			height: 100%;
			width: 100%;
			cursor: pointer;
			content: '';
		}
	}
}
