.minus{

    &::after{
        content: '\f056';
        font-family: $icon-font;
        color: $link;
        font-weight: 100;
        padding-left: var(--spacer-sm);
        font-size: 1.1em;
    }
}