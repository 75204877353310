.searchResults {
    width: 100%;
    padding: 1em 2em 4em 2em;
    border-right: 0.25px solid $grey-light;

    .pill .exit::after{
        top: 2.2px;
    }

    &__selected {
        padding: 1em 0;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $grey-light;
    }

    &__selectedHeading {
        padding-right: 1em;
        color: $black;
        font-weight: 300;
        font-size: 1em;
    }

    &__clearLink {
        &:hover {
            text-decoration: underline;
        }
    }

    &__linkRight {
        margin-left: auto;
        margin-bottom: 0;
    }

    &__saveLink {
        color: $grey;
        font-weight: 300;
    }

    &__add {
        &::after {
            font-size: 0.8em;
        }
    }

    &__pills {
        padding: 1em 0;
        border-bottom: 1px solid $grey-light;
        display: flex;
        flex-wrap: wrap;
        gap: 0.375rem;
    }

    &__controls {
        display: flex;
        flex-direction: column;
        padding: 1em 0;
        width: 100%;
        
        @include at(lg){
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        gap: 1em;
        
        .searchFilters__switchText {
            font-weight: 400;
            padding-left: 1.2rem;
        }
        
        @include at(md){
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
        }

        @include at(lg){
            justify-content: unset;
        }
    }

    &__info{
        display: flex;
        padding-top: 1em;
        flex-direction: column;
        gap: 1em;
        
        @include at(md){
            justify-content: space-between;
            flex-direction: row;
        }

        @include at(lg){
            width: 65%;
            padding-top: 0;
            align-items: center;
        }

    }

    &__checkbox {
        padding-right: 1.5em;
    }

    &__save {
        margin-left: auto;
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        flex-direction: column;
        column-gap: 4%;
        row-gap: var(--spacer-lg);

        @include at(md) {
            justify-content: flex-start;
            flex-direction: row;
            column-gap: 3%;
        }
    }

    &__resultsNumber, &__limit {
        font-size: 0.875em;
        color: $black;
        font-weight: 400;
    }

    &__limit {
        display: flex;
        align-items: center;
    }

    &__simpleCard {
        display: block;
        padding: 1em;
        margin-top: 1em;
    }
}
