.resources {
    $b: &;

    margin-top: 1em;

    &__header {
        font-size: 2em;
        font-family: $family-sans-serif;
        font-weight: 400;
        padding-bottom: 2.4rem;

        @include at(md) {
            font-size: 3rem;
        }

        &.-eyebrow {
            padding-bottom: 0;
        }
    }

    &__subHeader {
        font-size: 1.5rem;
        font-weight: normal;
        padding-bottom: 1.75rem;
        margin-top: 0;

        &.-eyebrow {
            padding-bottom: 0;
        }
    }

    &__eyebrow {
        font-weight: 300;
        font-size: var(--font-size);
        padding: 0.5em 0 0;
        text-transform: none;
        padding-bottom: 1.75em;

        a {
            text-decoration: underline;
        }
    }

    &__formLabel {
        font-weight: bold;
    }

    &__checkbox {
        padding: 0.5em 0;

        .checkbox__label {
            font-size: 1em;
        }
    }

    &__helper {
        font-weight: 300;
        padding-left: 1em;
    }

    label + input,
    label + textarea,
    label + select,
    &__textField {
        margin-top: 0.75em;
    }

    fieldset {
        margin-bottom: 3em;
    }

    &__textHeader {
        margin-bottom: 0;
    }

    &__leadingText {
        padding-top: 0;
        padding-bottom: 2em;
        font-weight: 100;
        font-size: 1em;
    }

    &__text {
        padding: 2em 0;
        font-weight: 100;
        font-size: 1em;
    }
}
