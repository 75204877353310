///
/// custom styled checkbox
///

.checkbox {
    $b: &;

    align-items: center;
    display: flex;
    position: relative;
    cursor: pointer;

    &.-assetCard {
        width: 100%;

        #{$b}__input:not(:checked) + .checkbox__label:before,
        #{$b}__input:checked + .checkbox__label:before {
            top: -10px;
        }

        #{$b}__input:not(:checked) + .checkbox__label:after,
        #{$b}__input:checked + .checkbox__label:after {
            top: -5px;
        }
    }

    &__label {
        color: $black;
        display: block;
        font-size: 0.875em;
        font-weight: 400;
        padding-left: 2rem;
        text-transform: none;
    }

    &__input:not(:checked),
    &__input:checked {
        -webkit-appearance: none;
        appearance: none;
        border: none;
        left: 0;
        position: absolute;
        height: 20px;
        top: 0;
        width: 20px;
        z-index: 1;
    }
    
    &__input:not(:checked) + .checkbox__label,
    &__input:checked + .checkbox__label {
        position: relative;
        cursor: pointer;
    }

    /* checkbox aspect */
    &__input:not(:checked) + .checkbox__label:before,
    &__input:checked + .checkbox__label:before {
        background-color: #fff;
        border: 1px solid $grey-darker;
        border-radius: 4px;
        content: '';
        height: 20px;
        left: 0;
        position: absolute;
        width: 20px;
        top: 2px;
    }

    &__input:checked + .checkbox__label:before {
        border-color: $vistana-blue;
    }
    /* checked mark aspect */
    &__input:not(:checked) + .checkbox__label:after,
    &__input:checked + .checkbox__label:after {
        font-family: $icon-font;
        content: '\f00c';
        display: flex;
        justify-content: center;
        height: 22px;
        left: 0;
        position: absolute;
        top: 3px;
        transition: all 0.2s;
        width: 22px;
    }
    /* checked mark aspect changes */
    &__input:not(:checked) + .checkbox__label:after {
        opacity: 0;
        transform: scale(0);
    }
    &__input:checked + .checkbox__label:after {
        opacity: 1;
        transform: scale(1);
    }
    /* disabled checkbox */
    &__input:disabled:not(:checked) + .checkbox__label:before,
    &__input:disabled:checked + .checkbox__label:before {
        box-shadow: none;
        border-color: transparent;
        background-color: rgba($blue-darker, 0.15);
    }
    &__input:disabled:checked + .checkbox__label:after {
        opacity: 0.5;
    }
    &__input:disabled + .checkbox__label {
        opacity: 0.7;
    }
    /* accessibility - TO DO */
    // &__input:checked:focus + .checkbox__label:before,
    // &__input:not(:checked):focus + .checkbox__label:before {
    //     border: 1px dotted $vistana-blue;
    // }

    // /* hover style just for information */
    // .checkbox__label:hover:before {
    //     background-color: rgba($vistana-blue, 0.15);
    // }
}
