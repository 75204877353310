.dropdown {
    position: relative;

    &__toggle{
        display: inline-block;
        padding: var(--spacer-sm);
    }

    &__list:last-child{
        padding-bottom: 1em;
    }

    &__link {
        display: inline-block;
        padding: var(--spacer-sm);
        z-index: 2;
        color: $white;
        font-family: $family-sans-serif;
        font-size: var(--font-size);
        padding: 1em 4em;    
    }

    &__button {
         background: transparent;
         border: none;
    }

    &__icon::after {
        font-family: $icon-font;
        content: '\f078';
        transition: all .2s;
        color: white;
        font-weight: 100;
    }


    &__content {
        position: absolute;
        z-index: 1;
    }

    &.-open {

        .dropdown__content {
            background-color: $black;
            box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
        }
    }

    &.-rightEdge {
        .dropdown__content {
            left: auto;
            right: 0;
        }
    }

    &.-fullWidth {
        position: static;

        .dropdown__content {
            left: 0;
            right: 0;
        }
    }

    &__transition {
        &-enter-active {
            transform-origin: top;
            //transition: opacity $moderate, transform $moderate;
            transform: rotateX(0);
        }
        &-enter,
        &-leave-to {
            opacity: 0;
            transform: rotateX(90deg);
        }
    }
}
