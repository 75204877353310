.share{
    &__heading{
        color: $grey;
    }

    .search{
        padding: .75em;
        background-color: #EEE;
        color: $grey;
    }
}