.assetCollection {
    position: fixed;
    bottom: 0px;
    z-index: 50;
    display: flex;
    width: 100%;
    flex-direction: column;
    --tw-bg-opacity: 1;
    background-color: $coral;
    color: $blue-darker;
    max-height: 75vh;

    &__wrapper {
        position: relative;
    }

    &__navItems {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        row-gap: 0.25rem;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #ff4731;

        @include at(lg) {
            flex-direction: row;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }

        @apply shadow;
    }

    &__total,
    &__actionSelect,
    &__contactSheet,
    &__download,
    &__workspace,
    &__workspaceButton,
    &__workspaceActionButton {
        font-weight: 600;
        text-transform: capitalize;
    }

    &__total {
        margin-right: 0.5rem;
        display: inline-block;
    }

    &__showSelected,
    &__deselect {
        font-size: 0.75rem;
        font-weight: 300;
        text-transform: capitalize;
        color: $blue-darker;
        background: transparent;
        border: none;
        text-decoration: underline;
        transition: all .2s;

        &__xsmall {
            padding-top: 0px;
            padding-bottom: 0px;
            padding-left: 0.25rem;
            padding-right: 0.25rem;
            font-size: 0.75rem;
        }

        &:hover {
            text-decoration-color: transparent;
        }
    }

    &__actionSelect {
        display: flex;
        align-items: center;
    }

    &__select {
        margin: 0px;
        display: inline-block;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-style: none;
        background-color: transparent;
        font-size: 0.875rem;
        color: $blue-darker;
        outline: 2px solid transparent;
        outline-offset: 2px;
        width: 125px;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='rgb(29, 31, 42)' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
        background-position: right 0.45rem;
        background-repeat: no-repeat;
        background-size: 1.5em 1.5em;

        .dropdown__icon {
            transition: none;
            color: $blue-darker;
        }
    }
    
    &__select option {
        color: $blue-darker;
    }

    &__contactSheet,
    &__workspaceButton,
    &__workspaceActionButton {
        display: inline-block;
        cursor: pointer;
        color: $blue-darker;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        text-align: center;
        font-weight: 600;
        text-transform: uppercase;
        text-decoration: underline;
        text-decoration-color: transparent;
        transition-property: background-color, border-color, text-decoration, color, fill, stroke;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;

        &:hover {
            text-decoration-color: $blue-darker;
        }
    }

    &__workspaceSelect {
        display: flex;
        flex-direction: row;
        font-size: 0.875rem;
        position: relative;
    }

    &__workspaceInput {
        font-size: 0.875rem;
        width: 50%;
    }

    &__workspaceList {
        position: absolute;
        max-height: 24rem;
        width: 100%;
        overflow: hidden;
        overflow-y: scroll;
        border-radius: 0.25rem;
        border-width: 1px;
        --tw-bg-opacity: 1;
        background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
        padding: 1rem;
        line-height: 1.625;
        --tw-text-opacity: 1;
        color: $blue-darker;
        --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        bottom: calc(100% - 0.5rem);
        list-style-type: none;
        padding-right: 2em;
    }

    // &__workspaceButton,
    // &__workspaceActionButton {
    //     background-color: transparent;
    //     border: none;
    //     color: $blue-darker;
    //     font-size: 0.875em;
    //     padding-left: 1em;

    //     &.-bold {
    //         font-weight: bold;
    //     }
    // }

    &__download {
        display: flex;
        align-items: center;
    }

    &__workspaceAction {
        position: relative;
    }

    // &__workspaceActionButton {
    //     color: $blue-darker;
    //     right: -0.25rem;
    //     top: 0.25rem;
    //     position: absolute;
    //     font-size: 1em;
    // }
}
