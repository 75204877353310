.torso {
	background-color: $grey-lighter;
	min-height: 500px;

	&__container{
		// min-height: 100vh;
		padding-top: 30px;
	}

	&__heading{
		color: $grey;
		font-size: 1em;
		display: flex;
		align-items: center;
		font-weight: 300;
		padding: 1.5em 0;
	}
}