@charset "utf-8";

// Import Roboto Google Font
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import 'vendor/fontawesome/fontawesome.scss';
// @import 'fontawesome/fa-solid';


$family-sans-serif: "Montserrat", sans-serif;
$family-heading: 'Roboto', sans-serif;
$fa-font-path: '/sup/build/fonts/';
$icon-font: 'Font Awesome 5 Pro';

// Navbar Customizations
$navbar-item-img-max-height: 152px;

.fa {
	font-family: $icon-font;
  font-weight: 400;
}


$breakpoints: (
  md: 616px,
  lg: 1000px,
  xl: 1288px,
);