.searchInputGroup {
    &__checkboxGroup {
        border-radius: 0.25rem;
        border-width: 1px;
        padding: 0.75rem;
        border: 0.5px solid $grey-light;
        max-height: 15rem;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    &__list {
        list-style-type: none;
        padding: 0;
    }

    &__item {
        color: $black;
        font-size: var(--font-size-sm);
        font-weight: 300;
        margin-top: 1em;
        position: relative;

        &:first-child {
            margin-top: 0;
        }
    }

    &__item:focus-visible {
        outline: $blue auto 1px;
     }

    &__label {
        
        .sr-only{
            top: 2px;
        }
        
        &:hover {
            text-decoration: underline;
    
        }
    
        &:focus-visible {
            outline: $blue auto 1px;
        }
    }

    &__selected {
        display: flex;
        padding-bottom: 1em;
        display: flex;
        flex-wrap: wrap;
        gap: 0.375rem;
    }

    &__textGroup {
        display: flex;
        max-height: 15rem;
        flex-wrap: wrap;
        overflow-y: scroll;
        border-radius: 0.25rem;
        padding: 0.75rem 0;
        gap: 0.375rem;
    }

    &__placeholder {
        background-color: transparent;
        border: none;
        text-decoration: underline;
        color: $grey-darker;
        width: 100%;
        text-align: right;
    }
}
