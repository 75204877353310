// sidebar styles (used on account pages)
.sidebar {
    background-color: $blue;
    color: $white;
    padding: 1rem;

    &__title {
        color: $white;
        font-weight: 400;
        text-transform: uppercase;
    }
    &__list {
        list-style: none;
        padding-left: 0;
    }
    &__link {
        color: $white;
        font-weight: 400;
        text-decoration: none;
        transition: all 0.2s;

        &:hover {
            // color: darken($white, 20%);
            color: $white;
            text-decoration: underline;
        }
    }
}
