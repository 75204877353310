.brand-guide {
    h2 {
        margin-bottom: 1rem;
    }
    .hero {
        background-size: cover;
        background-position: center;
        margin-left: auto;
        margin-right: auto;
        width: 100%;

        .gradient {
            background: linear-gradient(
                to bottom, 
                rgba(0, 58, 93, 0.4), /* Semi-transparent at the top */   
                rgba(0, 58, 93, 0.9)   /* Mostly opaque at the botton */
            );

            div.brand-guide-logo {
                position: relative;
                height: 200px;
                width: 400px;

                span.shadow {
                    bottom: 0;
                    left: 0;
                    position: absolute;
                }
            }

            div.subtitle {
                color: $white;
                span.text {
                    display: inline-block;
                    padding: 1rem 0 0 0;
                }
            }
            h1.title {
                color: $white;
                font-size: 2rem;
                font-weight: normal;
                span.text {
                    display: inline-block;
                    padding: 0.4rem 0 1.6rem 0;
                }
            }
        }

        &.dark {
            .gradient {
                background: linear-gradient(
                    to bottom, 
                    rgba(0, 0, 0, 0.4),
                    rgba(0, 0, 0, 1)
                );
                div.subtitle {
                    color: $coral;
                }
            }
        }
        &.light {
            .gradient {
                background: linear-gradient(
                    to bottom, 
                    rgba(255, 255, 255, 0.4),
                    rgba(255, 255, 255, 1)
                );
                div.subtitle {
                    color: $black;
                }
                h1.title {
                    color: $black;
                }
            }
        }
        &.transparent {
            .gradient {
                background: transparent;
                div.subtitle {
                    span.text {
                        background-color: rgba(0, 58, 93, 1);
                        color: $white;
                        padding: 1rem 2rem;
                    }
                }
                h1.title {
                    span.text {
                        background-color: rgba(255, 255, 255, 0.7);
                        color: $black;
                        padding: 1rem 2rem 1.6rem 2rem;
                    }
                }
                div.brand-guide-logo span.shadow {
                    background-color: rgba(0, 0, 0, .7);
                }
            }
        }
    }
    .contents-menu {
        background-color: $white;
        color: $blue;
        padding: 1rem 0;
        .flex {
            display: flex;
            gap: 1rem;
        }
    }
    .contents-menu {
        position: sticky;
        top: 167px;
        z-index: 900;
    }
    .text-content {
        line-height: 1.6rem;
    }
    .grid {
        @include at(md){
            display: grid;
            grid-template-columns: 2fr 1fr;
            grid-column-gap: 1rem;

            // &.reverse {
            //     grid-template-columns: 1fr 2fr;
            // }

            &.collection,
            &.color,
            &.font {
                grid-template-columns: 1fr 2fr;
            }
            &.cta {
                grid-template-columns: 3fr 1fr;
            }
        }
        .primary {
            font-size: 1.1rem;
            line-height: 2rem;
        }
        .side {
            hr {
                margin: 1.5rem 0;
            }
        }
        .call-to-action {
            background-color: $blue;
            border-radius: .8rem;
            color: $white;
            padding: 1.6rem;

            .call-to-action__title {
                display: grid;
                grid-template-columns: auto 1fr;
                gap: .8rem;
                margin-bottom: 1rem;
                .title-icon {
                    background-color: $coral;
                    font-size: 2rem;
                    padding: .4rem .8rem
                }
                .title-text {
                    font-size: 1.2rem;
                    font-weight: bold;
                }
            }
            a {
                color: $white;
                &:hover {
                    color: $lightblue;
                }
            }
            .error {
                font-size:.9rem;
                a {
                    color: #B54A4A;
                    &:hover {
                        color: $red;
                    }
                }
            }
        }
    }
    .color-grid {
        @include at(md){
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 1rem;

            &.primary {
                grid-template-columns: repeat(3, 1fr);
            }
        }
        .color-block {
            border: 2px solid $grey-light;
            font-size: .85rem;
            padding: 6px;

            .details {
                height: 100%;
                padding: 8px;
            }
            .--dark {
                color: #FFF;
            }
        }
    }
    .brand-guide-section {
        background-color: $white;
        margin: 3rem 0;
        padding: 2rem;

        .loading-spinner {
            color: $cyan;
            font-size: 1.5rem;
            text-align: center;
            margin-top: 3.2rem;
        }

        .thumbnails {
            display: grid;
            grid: repeat(2, minmax(0, 1fr)) / minmax(0, 2fr) repeat(3, minmax(0, 1fr));
            gap: 1.8rem;

            .thmbnl {
                aspect-ratio: 1;
                background-color: #EEE;
                background-size: cover;
                background-position: 50% 50%;
            }
            .first {
                aspect-ratio: auto;
                grid-row: 1/3;
            }
            .display-toggle-block {
                border: 2px solid $blue;
                color: $blue;
                font-size: 1.6rem;
                grid-column: 3 / span 2;
                padding: 1.6rem 1rem;
                text-align: center;

                .button {
                    display: inline-block;
                    font-size: 1.2rem;
                    margin-top: 1rem;
                    padding: .6rem 1.2rem;
                }

                &.block-open {
                    border-color: $coral;
                    color: $coral;

                    .button {
                        background-color: $coral;
                    }
                }
            }
        }
    }
}