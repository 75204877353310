/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    border-radius: 34px;

  
  /* Hide default HTML checkbox */
  &__input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
        width: 26px;
    height: 16px;

    &.round{
        border-radius: 34px;
    }

    &.round:before{
        border-radius: 50%;
    }
  }
  
  &__slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
}
  
  input:checked + .switch__slider {
    background-color: #4C7B4C;
  }
  
  input:focus + .switch__slider {
    box-shadow: 0 0 1px #4C7B4C;
  }
  
  input:checked + .switch__slider:before {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);
  }
  
