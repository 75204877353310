.selectedAssets{
    background: $white;
    padding: 0 2em 2em;
    overflow: auto;

    &__assets{
        display: flex;
        flex-wrap: wrap;
        row-gap: 1rem;
        padding-top: 1rem;
    }

    &__asset{
        width: 100%;
   
        @include at (md){
            width: 33.333%;
        }

        @include at (lg){
            width: 25%;
        }
    }
}