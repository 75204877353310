.assetThumbnail {
    $b: &;

    position: relative;
    text-align: center;

    &::before,
    &::after {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        transition: opacity 0.2s ease-out;
    }
    &::before {
        background-color: rgba($black, 0.25);
        content: "";
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
    }

    &::after {
        bottom: 0.25em;
        color: $white;
        content: "\f0b2";
		font-family: $icon-font;
		font-size: 2em;
        line-height: 1;
		right: 0.25em;
		z-index: 20;
    }

    &:hover {
        &::before,
        &::after {
            opacity: 1;
        }
    }

    &__img {
        cursor: pointer;
        max-height: none;
        width: 100%;

        &.portrait {
            width: auto;
        }
    }
}