.caret{
    position: relative;

    &::after{
        color: $white;
        font-family: $icon-font;
        transition: all .2s;
        font-weight: 100;
        padding-left: .25em;
        font-size: 1.5em;
        content: '\f107';    
    }

}

.caretRight{
    position: relative;

    &::after{
        color: $link;
        font-family: $icon-font;
        transition: all .2s;
        font-weight: 100;
        padding-left: .25em;
        font-size: 1.5em;
        content: '\f105';    
    }
 
}

.caretLeft{
    position: relative;

    &::after{
        color: $link;
        font-family: $icon-font;
        transition: all .2s;
        font-weight: 100;
        padding-left: .25em;
        font-size: 1.5em;
        content: '\f104';    
    }
 
}