///
/// TABS allows for the showing and hiding of panels via an associated tab
/// navigation. By adding an optional data element (data-hash="#myHash") to
/// the li tags the javascript plugin will add the hash to the URL so users
/// may bookmark the page and the page can reload open to that tab when the
/// the url with the hash is loaded in the browser.
///

@mixin tabs() {
    $b: &;

    &__list {
        list-style-type: none;
        overflow: hidden;
        display: flex;
		align-items: center;
		border-bottom: 1px solid $grey-light;
		padding: 30px 0px 10px;

    }

    &__tab {
        border: none;
		background-color: transparent;
		width: 33%;
		position: relative;
        text-align: center;
        font-family: $family-sans-serif;
        font-size: .75em;
        color: $grey;
        text-transform: uppercase;
        font-weight: normal;

        @include at(md){
            width: 300px;
            font-size: 1.5em;
        }

        &:hover{
            color: $black;
            
            &::after{
                content: "";
                border-color: $coral;
                border-bottom-width: 4px;
                border-bottom-style: solid;
                display: block;
                position: absolute;
                left: 0px;
                right: 0;
                bottom: -12px;
            }
        }

		&.-active{
            font-weight: 600;
            color: $black;
		
		&::after{
			content: "";
			border-color: $coral;
			border-bottom-width: 6px;
			border-bottom-style: solid;
			display: block;
			position: absolute;
			left: 0px;
			right: 0;
			bottom: -12px;
		}
    }
}

    &__button {
        border: none;
        background: none;
        cursor: pointer;
        font-family: "Montserrat", sans-serif;
        font-size: 1.5em;
		color: $grey;
		font-weight: 300;
    }

    &__panel {
        padding-top: .5rem;
    }

}

.tabs {
    @include tabs;
}