.pagination {

    &__breakView {
        &:nth-child(3) {
            .pagination__goToPage {
                display: none;
            }
        }
    }

    &__goToPage {
        // left: calc(50% - theme('width.11') / 2);
    }

    &__icon {
        color: $blue-darker;
        &:hover {
            color: darken($blue-darker, 10%);
        }
    }

    &__item {
        &.-active .pagination__link, {
            font-weight: 700;
        }
    
        &.-disabled .pagination__link {
            color: $grey;    
        }
    }

    &__link {
        display: inline-block;
        padding: 0 0.25rem;
        &:hover {
            color: $blue-darker;
        }
    }

    
}
