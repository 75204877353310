.recentSearches{

    &__head{
        display: flex;
        justify-content: space-between;
        background-color: $white;
        color: $grey-dark;
        border-bottom: 1px solid $grey-light;
        padding: .5em;

        button{
            border: none;
            background-color: transparent;
            font-family: $family-sans-serif;
            font-size: var(--font-size);

            &.-active{
                color: $blue-darker;
                font-weight: bold;
            }
        }
    }

    &__foot{
        width: 100%;
        display: flex;
        position: absolute;
        justify-content: space-between;
        background-color: $white;
        color: $grey-dark;
        border-top: 1px solid $grey-light;
        margin-top: 20rem;

        button {
            width: 50%;
            border: none;
            background-color: $grey-lighter;
            font-family: $family-sans-serif;
            font-size: var(--font-size);
            color: $blue-darker;
            padding: .5em 1em;
            border: $grey-light;
            border: 1px solid $grey-light;
            transition: background-color .2s ease-in-out;

            &:hover{
                background-color: $grey-light;
            }

            &:disabled{
                color: $grey-light;
                background-color: $grey-lighter;
            }
        }
    }

    &__list{
        width: 100%;
        position: absolute;
        height: 20rem;
        overflow-y: scroll;
        list-style: none;
        padding: 0 1em 1em 1em;
        background-color: #FFFFFF;
        z-index: 2;
        box-shadow: 0px 0px 4px #d4d5d8;
    }

    &__listItem{
        margin-bottom: 0.25rem;
    }

    &__input{
        border-radius: 0.25rem;
        font-size: 0.875em;
    }
}