.assetCard {
    $b: &;

    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 4px $grey-light;
    border-radius: 0.125rem;
    background-color: $white;
    height: 100%;
    margin: 0.5rem;
    // width: 100%;

    // @include at (md){
    //     width: 30%;
    // }
    // @include at (lg){
    //     width: 22%;
    // }

    // &.-withFilters{
    //     @include at (md){
    //         width: 48%;
    //     }
    //     @include at (lg){
    //         width: 30%;
    //     }
    // }

    .pill {
        margin-left: auto;
    }

    .drawer {
        position: relative;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        &__contents {
            left: -1px;
            position: absolute;
            z-index: 50;
            border: 1px solid $grey-light;
            border-top-width: 0px;
            border-top-color: checked;
            --tw-bg-opacity: 1;
            background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
            padding: 1rem;
            width: calc(100% + 2px);
            top: 100%;
        }

        &__links {
            list-style-type: none;
            padding-left: 0;

            li a {
                font-family: $family-sans-serif;
                font-weight: 300;
                font-size: var(--font-size-sm);
                color: $black;
            }
        }
    }

    &__body {
        display: flex;
        height: 100%;
        flex-direction: column;
        align-items: stretch;
        gap: 0.5rem;
        padding: 1rem;
    }

    &__status {
        display: flex;
        justify-content: space-between;
        padding-bottom: 0.5em;
    }

    &__imageWrapper {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    &__image {
        width: 100%;
        object-fit: contain;
        object-position: center;
        height: 100%;
    }

    &__fileName {
        margin-left: auto;
        margin-right: auto;
    }

    &__fileID {
        font-weight: 300;
        font-size: var(--font-size-sm);
    }

    &__edit {
        font-size: .75rem;
    }


    &__content {
        border-top: 1px solid $grey-light;
        padding-top: var(--spacer-sm);
    }

    &__content p {
        padding-bottom: 0.125em;
    }

    &__heading {
        display: flex;
        flex-direction: column;
    }

    &__edit {
        text-decoration: none;
        transition: text-decoration .2s;
    }

    &__eyebrow {
        font-size: 0.8125em;
        color: $black;
    }

    &__title {
        font-size: var(--font-size);
        font-weight: bold;
        color: $black;
        padding: 0.25em 0;
    }

    &__mustache {
        font-size: var(--font-size-sm);
        font-weight: 500;
        padding-bottom: 0.5em;
        color: $black;
    }

    &__meta {
        padding-top: 1.5em;
    }

    &__date {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        align-items: center;
        gap: 0.25rem;
        width: 80%;
    }

    &__date dt,
    &__date dd {
        font-size: var(--font-size-sm);
        font-weight: 300;
        margin: 0;
    }

    &__switch {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        align-items: center;
        gap: 0.25rem;
        width: 80%;
        padding-top: 0.5em;

        .switch__slider {
            top: 2px;
        }
    }

    &__switch dt,
    &__switch dd {
        font-size: var(--font-size-sm);
        font-weight: 300;
        margin: 0;
    }

    &__actions {
        display: flex;
        justify-content: space-around;
        border-top: 1px solid $grey-light;
    }

    &__links {
        width: 50%;
        text-align: center;
        padding: 0.5em 0;
        display: flex;
        justify-content: center;
        align-items: center;

        &.-border {
            border-right: 1px solid $grey-light;
        }

        &:hover {
            #{$b}__arrow {
                transform: translateX(0.25em);
            }
        }
    }

    &__link {
        color: $black;
        font-size: 0.875rem;
        font-weight: 400;

        &:hover{
            color: $link;
            text-decoration: none;
        }
    }

    &__button {
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        font-family: $family-sans-serif;
        font-weight: 400;
        color: $black;
        font-size: 0.875rem;
    }

    &__arrow{
        transition-property: transform;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms; 
        position: relative;
    }


    &__chevron {
        display: flex;
        align-items: center;
        transition-property: transform;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
    }

    &__arrow::after,
    &__chevron::after,
    &__plus::after {
        color: $link;
        font-family: $icon-font;
        transition: all 0.2s;
        font-weight: 300;
        padding-left: var(--spacer-sm);
    }

    &__plus {
        display: flex;
        align-items: center;
        transition-property: transform;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
    }

    &__plus::after {
        content: '\2b'
    }

    &__arrow::after {
        content: '\f061';
        font-size: 0.75em;
        position: absolute;
        bottom: -8px;
    }

    &__chevron::after {
        content: '\f078';
        font-size: 1em;
    }

    .drawer {
        :hover {
            #{$b}__chevron {
                transform: translateY(0.25em);
            }

            #{$b}__button {
                color: $link;
            }
        }
    }
}
