.toggleBox{

    padding-bottom: 1em;

    &:last-child{
        padding-bottom: 0;
    }

    &__header{
        display: flex;
        cursor: pointer;
    }

    &__title{
        font-weight: 300;
        font-size: .875em;
        margin-right: auto;
    }

    &__meta{
        font-size: .75em;
        color: $grey-light;
    }

    &__control{
        background-color: transparent;
        border: none;
        margin-left: .75em;
    }

    &__content{
        padding-top: var(--spacer-sm);

        &.-closed{
            padding-top: 0;
        }
    }
}