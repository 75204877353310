.exit{
    position: relative;


    &::after{
        color: $black;
        font-family: $icon-font;
        transition: all .2s;
        font-weight: 100;
        padding-left: 0.5em;
        font-size: var(--font-size-sm);
        content: '\f00d';
    }

}