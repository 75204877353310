.contentFlex {
	display: flex;
	flex-direction: column; 
	
	
	@include at (md) {
		align-items: flex-start;
		display: flex;
		flex-flow: row nowrap;
		
		&__sidebar {
			flex-basis: 20%;
		}

		&__content {
			flex-basis: 80%;
			padding-left: 5rem;
		}
	}
}