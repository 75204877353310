/**
 * MVW Styles
 *
 * File organization:
 *   1  Fonts
 *   2  Base Tag Styles
 *   3  Structural Tags
 *   4  Custom Tags
 *   5  Page-Specific Tags
 *   └─ 5.1  Home
 *      5.2  Standard Page Styles
 *      5.3  Basic Grid System
 *
 * Colors used:
 *   link-blue:        #00b4d7
 *   highlight-green:  #89c553
 *   primary-gray:     #757a7e
 *   secondary-gray:   #707a83
 *   tertiary-gray:    #90979f
 *   fourth-gray:      #5d676f
 *   shade-gray:       #f7f8fa
 *   dark-gray:        #272b2f
 *   background-white: #ffffff
 */


/* 2. Base Tag Styles
----------------------------------------------------------------------------- */

:root{
	--comp: #{1200px}; /* Width of comp to determine ideal font size */
	--vw-multiplier: calc(100 / var(--comp) * 1vw);
}

*{
	box-sizing: border-box;
}


html {
	//background: #272b2f;
	color: #757a7e;
	font-family: $family-sans-serif;
	margin: 0;
	padding: 0;
}

body {
	background: $grey-lighter;
	margin: 0 auto;
	padding: 0;
	width: 100vw;
	height:100vh;
	color: $black;
}

.main{
	min-height: 100vh;
}

a, button {
	cursor: pointer;
}

button {
	font-family: $family-sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1.2;
	margin: 0;
}

h1 {
	font-size: 1.8em;
}

h2 {
	font-size: 1.875em;
	font-weight: 300;
	text-transform: uppercase;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	margin-top: 1em;
}

	h2 .sub_head {
		font-size: 0.7em;
		text-transform: none;
		display: block;
	}

	h2:first-child {
		margin-top: 0;
	}

h3 {
	font-size: 1.125em;
	margin-top: 1.25em;
}

	h3:first-child {
		margin-top: 0;
	}

nav ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

	nav li {
		display: inline-block;
		margin-top: 0;
	}

hr {
	margin: 1.5em -16px;
	border: none;
	border-top: 1px solid #b9c1c5;
}

p,
ul,
ol,
dl,
form,
fieldset,
figure {
	margin: 0 0 1rem;
	color: $black;
}

	p:first-child,
	ul:first-child,
	ol:first-child,
	dl:first-child,
	form:first-child,
	fieldset:first-child,
	figure:first-child {
		margin-top: 0;
	}

	li {
		margin-top: 0.75em;
	}

	ul.shy {
		list-style: none;
		padding: 0;
	}

dt {
	font-weight: 600;
}

img {
	max-width: 100%;
}

// label {
// 	font-weight: 400;
// 	color: $black;
// 	font-size: 1em;
// 	text-transform: uppercase;
// }

label + input,
label + textarea,
label + select,
input + .quicksearch,
label + .select2-container {
	margin-top: 0.4em;
}

strong {
	font-weight: 600;
}

input,
textarea,
select {
	border: 1px solid #ccc;
	box-sizing: border-box;
	font-family: inherit;
	font-size: 1em;
	padding: .5em;
	width: 100%;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

a:-webkit-any-link:focus-visible{
    outline-offset: 5px;
	outline: $blue-darker auto 1px;
}
input[type=text] {
	color: $black;
	border-radius: 0.25rem;
}

input[type=text]:focus-visible, button:focus-visible {
    outline: $blue-darker auto 1px;
}

input:focus-visible{
	outline: $blue-darker auto 1px;
}

input[type="password" i]:focus-visible{
	outline: $blue-darker auto 1px;
}


input.small,
textarea.small,
select.small {
	font-size: 1em;
	padding: 0.4em 0.8em;
}

input[type=submit],
input[type=button] {
	width: auto;
}

input[type=radio] {
	width: auto;
	margin: 4px 0.3125em 0 0;
	padding: 0;
	float: left;
}

.option-content {
	margin-left: 24px;
}

::-webkit-input-placeholder {
	color: #a6afb7;
}

:-moz-placeholder {
	color: #a6afb7;
	opacity: 1;
}

::-moz-placeholder {
	color: #a6afb7;
	opacity: 1;
}

:-ms-input-placeholder {
	color: #a6afb7;
}

label.screen-reader-only {
	position: absolute;
	height: 1px;
	width: 1px;
	left: -999em;
}

fieldset {
	padding: 0;
	border: none;
}

	fieldset h2 {
		margin-bottom: 0.6em;
	}

.field {
	margin-bottom: 1em;
}

.helper {
	display: inline-block;
	margin-left: 1em;
}

.styled-select {
	height: 54px;
	overflow: hidden;
	background: #fff url('/sup/img/icons/drop-down.png') no-repeat scroll 95% 50%;
	background-size: 24px 24px;
}

	.styled-select select {
		background: transparent;
		-webkit-appearance: none;
		width: 110%;
		padding-top: 12px;
		outline: none;
	}

	.styled-select select:focus {
		outline: none;
	}

	.styled-select select.empty {
		color: #a6afb7;
	}

	.styled-select option {
		color: #757a7e;
	}

.required {
	color: $red;
}

table {
	margin: 1.5em 0 0;
	width: 100%;
	border-collapse: collapse;
	border-bottom: 1px solid #757a7e;
}

	table:first-child {
		margin-top: 0;
	}

	th {
		font-size: 0.875em;
		text-align: left;
		border-bottom: 1px solid #757a7e;
		padding: 0 12px 4px;
		vertical-align: bottom;
	}

	td {
		padding: 8px 12px;
		vertical-align: top;
		border-bottom: 1px solid #b9c1c5;
	}

	tr:nth-child(2n) td {
		background: #f0f1f3;
	}

	th.options,
	td.options {
		text-align: right;
	}


/* 3. Structural Tags
----------------------------------------------------------------------------- */

.container {
	padding: 0px 50px;
	margin: auto;
	max-width: 1394px;
}

.hide{
 display: none;
}


/* 4. Custom Tags
----------------------------------------------------------------------------- */

.login_head {
    margin-top: 40px;
}

.fine-print {
	font-size: var(--font-size-sm);
	margin-bottom: 1em;
}

.help {
	display: block;
	font-size: 0.875em;
	margin-top: 0.25em;
}

	.text.help {
		font-size: 1.125em;
		margin-top: 0;
	}

.delimiter {
	color: #b9c1c5;
	display: inline-block;
	margin: 0 0.125em;
}

	nav.interior h2 {
		font-size: 1.125em;
		font-weight: normal;
		border-bottom: 1px solid #b9c1c5;
		padding-bottom: 0.5em;
		margin-bottom: 0.5em;
	}

	nav.interior a {
		text-transform: none;
	}

	nav.interior li {
		display: block;
	}


.asset-details dt {
	float: left;
	width: 35%;
	clear: left;
	margin-bottom: 0.5em;
}

.asset-details.full dt {
	width: 118px;
}

.asset-details.full dd {
	margin-left: 133px;
}

.asset-details dd {
	margin-left: 40%;
	margin-bottom: 0.5em;
}

.shy i.fa {
	margin-right: 0.3125em;
}

.highlight {
	margin-top: 1em;
	background: #fcf1cc;
	padding: 16px 24px;
}

.highlight:first-child {
	margin-top: 0;
}

.grade {
	background: #f0f1f3;
	padding: 0.5em 1em;
}

	.grade h3 {
		font-weight: normal;
		font-size: 1.25em;
	}

	.grade span {
		display: inline-block;
		font-size: 4em;
		font-weight: 600;
		line-height: 1;
	}

.search_slider {
	background: #f0f1f3;
	padding: 0.6em 1.25em;
	cursor: pointer;
}

.search_advanced {
	background: #f0f1f3;
	margin-top: 0;
	padding: 0.6em 1.25em;
}

.date-range-delimiter {
	margin-top: 2.6em;
	display: block;
	text-align: center;
}

.viewed-searches li {
	margin-top: 0.625em;
}

	.viewed-searches li:first-child {
		margin-top: 0;
	}

.edit_search {
	display: none;
	background: #fff;
	border: 1px solid #90979f;
	padding: 1em 1.5em;
	clear: both;
	margin-top: 1em;
}

.result_overview .five {
	text-align: right;
}

.search-results {
	margin-top: 1em;
}

.search-results h3.result_category {
	background: #F0F1F3;
	padding: 0.5em 1em;
}

// .results {
// 	overflow: hidden;
// 	margin: 1em 0 0;
// }

.workspace-asset {
	padding: 0.6em 1em;
	background: #f0f1f3;
	margin-top: 1.25em;
}

	.workspace-asset:first-child {
		margin-top: 0;
	}

.results .result {
	box-sizing: border-box;
	width: 30.6667%;
	float: left;
	margin-left: 4%;
	margin-bottom: 1.25em;
	display: table-row;
	background: #f0f1f3;
	padding: 12px;
}

	.results .result.inactive,
	.workspace-asset.inactive {
		background: #fce0e2;
	}

	.results .result:nth-child(3n-2) {
		margin-left: 0;
		clear: left;
	}

.result .thumbnail,
.thumbnail {
	height: 267px;
	width: 267px;
	display: table-cell;
	text-align: center;
	vertical-align: middle;
	position: relative;
}

.workspace-asset .thumbnail {
	width: 154px;
	height: 154px;
}

.workspace-asset .remove_from_workspace {
	font-size: 0.875em;
	margin-top: 0.6em;
}

	.workspace-asset .remove_from_workspace input[type=checkbox],
	.workspace-asset .remove_from_workspace input[type=radio] {
		margin-top: 3px;
	}

/*
select2's poor choice to use `results` and `result` in their table with
no easy way to single things out forces us to now unset things we've already
created above
*/
table.results {
	border: 1px solid #b9c1c5;
	background: #fff;
}

	table.results .result {
		width: auto;
		float: none;
		box-sizing: border-box;
		margin-left: 0;
		margin-bottom: 0;
		display: inherit;
	}

	table.results tr.result:nth-child(n) td {
		background: transparent;
	}

	table.results .result {
		cursor: pointer;
		background: transparent;
	}

	table.results .result:hover td {
		background: #f0f1f3 !important;
	}

#workspace_list {
	margin-bottom: 2em;
}

	.result .thumbnail a,
	.result .thumbnail span,
	.thumbnail a,
	.thumbnail span {
		display: block;
		width: 100%;
		height: 0;
		padding-bottom: 100%;
		//background: url('https://via.placeholder.com/150') no-repeat center center / 200px auto;
	}

	.result .thumbnail.with-enlarge a:after,
	.thumbnail.with-enlarge a:after {
		background: rgba(0, 0, 0, 0.6);
		transition: all 180ms ease;
		visibility: hidden;
		opacity: 0;
		display: block;
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		z-index: 10;
		content: "";
	}

	.result .thumbnail.with-enlarge a:before,
	.thumbnail.with-enlarge a:before {
		content: "\f0b2";
		font-family: FontAwesome;
		font-size: 2em;
		position: absolute;
		bottom: 0.25em;
		right: 0.25em;
		line-height: 1;
		z-index: 20;
		color: #fff;
		visibility: hidden;
		opacity: 0;
	}

	.result .thumbnail.with-enlarge a:hover:after,
	.result .thumbnail.with-enlarge a:hover:before,
	.thumbnail.with-enlarge a:hover:after,
	.thumbnail.with-enlarge a:hover:before {
		opacity: 1;
		visibility: visible;
	}

.result .result-info {
	margin-top: 0.5em;
}

.result .caption {
	font-weight: 600;
}

.result .topic {
	margin-bottom: 0.5em;
}

.result .filename {
	color: #90979f;
	font-size: 0.875em;
	font-weight: 600;
}

.result .result-date {
	margin-top: 0;
	color: #90979f;
	font-size: 0.875em;
	margin-bottom: 1em;
}

.result .download {
	margin: 0 -12px;
	padding: 0 12px;
	font-size: 0.875em;
	border-top: 1px solid #B9C1C5;
	border-bottom: 1px solid #B9C1C5;
}

.results .download_dialog {
	display: block;
	margin: 0 -12px;
	padding: 6px 12px;
}

.result .remove_from_workspace {
	font-size: 0.78em;
	margin-top: 0.5em;
}

.result input[type=checkbox] {
	margin-top: 3px;
}

.result .download .usage_description,
.result .download .expiration_date,
.result .download .additional_info,
.result .workspace_image_info .usage_description,
.result .workspace_image_info .expiration_date,
.result .workspace_image_info .additional_info,
.asset-actions .usage_description,
.asset-actions .expiration_date,
.asset-actions .additional_info {
	display: none;
}

.result .download .shy {
	margin-bottom: 0.5em;
}

.result .options {
	margin-top: 1em;
	font-size: 0.875em;
}

	.result .options input[type=checkbox] {
		margin-top: 3px;
	}

.result_action {
	margin: 1em 0 0;
}

.result_action .action {
	margin-top: 0.5em;
}

.results_action .action:first-child {
	margin-top: 0;
}

.sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.confirmation .field{
	display: flex;
	flex-direction: column-reverse;
}

.confirmation .helper{
	margin-bottom: 1em;
	margin-left: 0;
}

.confirmation__message{
	padding: 1em 0;
}


/* 5. Page-Specific Tags
----------------------------------------------------------------------------- */

.accountPage{
	padding: 2em;
}


/* 5.2. Standard Page Styles ------------------------------------------------ */
.content_container {
    margin-top: 40px;
	padding: 20px 50px 50px;
}

.user_type_detail {
	margin-top: 0.6em;
}

.vendor_fields {
	margin-top: 1em;
}

.success {
	background: #c8fcd0;
	border-left: 12px solid #41b544;
	padding: 0.75em 1.25em;
	margin-top: 1em;
	color: #606060;
}

.error {
	background: #fcc7c7;
	border-left: 8px solid #b54a4a;
	padding: 0.75em 1.25em;
	margin-top: 1em;
	color: #606060;
}

.info {
	background: #d1e9fc;
	border-left: 12px solid #5bb6fc;
	padding: 0.75em 1.25em;
	margin-top: 1em;
	color: #606060;
}

.warning {
	background: #fcf1cc;
	border-left: 12px solid #efcf69;
	padding: 0.75em 1.25em;
	margin-top: 1em;
	color: #606060;
}

	.warning ul {
		list-style: none;
		padding: 0;
	}

.error:first-child,
.info:first-child,
.warning:first-child {
	margin-top: 0;
}


	.news .article-datetime {
		margin-top: 0;
		font-size: 0.9125em;
		font-weight: bold;
		margin-bottom: 2em;
		color: #90979f;
	}

	.pagination{
		list-style-type: none;
		display: flex;
		gap: 0.5em;
		margin-bottom: 0;
		padding: 0;
	}

	.pagination li{
		margin-top: 0;
		
		a{
			color: $black;
			font-size: .875rem;
			font-weight: 400;

		}

		&.active{
			a{
				font-weight: bold;
			}
		}
	}

.page_list_pagination {
	.pagination {
		text-align: center;
		margin: 1.5em 0;
		padding-top: 0.75em;
		overflow: hidden;
		position: relative;
		clear: both;

		ul {
			list-style: none;
			margin: 0 auto;
			padding: 0;
			position: relative;

			&:before {
				content: '';
				display: block;
				width: 15%;
				left: -10%;
				border-top: 1px solid $blue;
				position: absolute;
				top: 50%;
				z-index: 1;
			}
			&:after {
				content: '';
				display: block;
				width: 15%;
				right: -10%;
				border-top: 1px solid $blue;
				position: absolute;
				top: 50%;
				z-index: 1;
			}

			li {
				background:none;
				display: inline-block;
				margin: 0;
				padding: 0 0.5em;
				position: relative;
				z-index: 10;

				a {
					color: $blue;
				}

				&.current a {
					color: $cyan;
					font-size: 1.5em;
					vertical-align: -0.125em;
				}

				&:last-child {
					padding-right: 1em;
				}

				&:first-child {
					padding-left: 1em;
				}

				&.skipped a {
					color: #757a7e;
					font-weight: normal;
				}
			}
		}
	}
}

.actions .field label {
	float: left;
	line-height: 2.5;
}

.actions form {
	float: left;
}


.actions .additional-actions {
	float: left;
	list-style: none;
	margin: 0 0 0 1em;
	line-height: 2.5;
	padding-left: 0;
}

.actions .field select {
	margin: 0 0 0.5em;
}

.actions .field .button {
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	display: inline-block;
}

.asset-actions .field {
	margin-top: 0;
	text-align: right;
}

.asset-actions form {
	margin-bottom: 1.25em;
	float: none;
}

.enlarge {
	text-align: right;
	font-size: 0.875em;
	padding-top: 0.5em;
}

.search-sorting {
	float: left;
	margin-top: 1.25em;
}

.search-actions {
	float: right;
	margin-top: 1.75em;
}


.toggle {
	display: block;
	width: 100%;
	overflow: hidden;
	background: #b9c1c5;
	border-radius: 0.25em;
	line-height: 1.75;
	cursor: pointer;
}

	.toggle.wide {
		width: 140px;
		display: inline-block;
		vertical-align: -0.3125em;
	}

	.toggle span,
	.toggle a {
		width: 50%;
		display: block;
		float: left;
		text-align: center;
		font-size: 0.75em;
		text-transform: uppercase;
		font-weight: bold;
	}


.visible_to_api_select_yes .toggle span.yes,
.toggle .yes.on {
	background: #41b544;
	color: #fff;
}

.visible_to_api_select_no .toggle span.no,
.toggle .no.on  {
	background: #b54a4a;
	color: #fff;
}

.visible_to_api_select_no .toggle span.yes {
	background: transparent;
	color: inherit;
}

.quicksearch {

}

/*Picker styles are in use for MVW Redesign*/

.picker {
	position: relative;
	padding: 0;
	overflow: scroll;
	display: flex
}

	.picker > .qsresults,
	.picker > .selected {
		box-sizing: border-box;
		width: 49%;
		margin-top: 0;
		padding: 1em 0;
	}

	.picker > .selected {
		margin-left: 2%;
	}

	.picker ul {
		box-sizing: border-box;
		background: #F0F0F0;
		height: 350px;
		overflow-x: hidden;
		overflow-y: auto;
		padding: .75em;
		list-style: none;
		margin-top: 1em;
	}

	.picker li {
		box-sizing: border-box;
		width: 100% !important;
		margin: 0.5em 0 0 !important;
		cursor: pointer;
		background: #FFF;
		border: #999 1px solid;
		font-size: 0.9375em;
		line-height: 1.35;
		float: none;
		padding: 6px;
	}


		.picker li.none {
			border: none;
			font-style: italic;
			background: transparent !important;
		}

	.picker ul.picked li {
		background: #c8fcd0;
	}

#rights_managed_notice {
	display: none;
}

.lightbox_action {
	list-style: none;
}

	.lightbox_action ul {
		list-style: none;
		padding: 0;
	}

	.lightbox_action .select_all,
	.lightbox_action .toggle_all {
		float: right;
		text-align: right;
	}

	.toggle_all {
		margin-top: 0.5em !important;
	}

	.lightbox_action label {
		float: left;
		line-height: 2.5em;
	}

	.lightbox_action select {
		float: left;
		margin-left: 0.5em;
		width: auto;
		margin-top: 0;
		max-width: 280px;
	}

	.lightbox_action input {
		float: left;
		margin-left: 0.75em;
		font-size: 0.875em;
		padding-top: 0.75em;
		padding-bottom: 0.75em;
	}


/* 5.3. Basic Grid System --------------------------------------------------- */
.row {
	position: relative;
	margin: 1.5em auto 0;
	padding: 0;
}

	.row:first-child {
		margin-top: 0;
	}

.content_container:before,
.content_container:after,
.row:before,
.row:after {
	content: " ";
	display: table;
}

.content_container:after,
.row:after {
	clear: both;
}

.column {
	display: block;
	float: left;
	margin: 0 0 0 4%;
}

	.column:first-child {
		margin-left: 0;
	}

.two.column {
	width: 13.3333%;
}

.three.column {
	width: 22%;
}

.four.column {
	width: 30.6667%;
}

.five.column {
	width: 39.3333%;
}

.six.column {
	width: 48%;
}

.seven.column {
	width: 56.6667%;
}

.eight.column {
	width: 65.3333%;
}

.nine.column {
	width: 74%;
}

.ten.column {
	width: 82.6667%;
}

.eleven.column {
	width: 91.3333%;
}

.full.column {
	width: 100%;
}

.one-fifth.column {
	width: 16.8%
}

.two-fifths.column {
	width: 37.6%
}

.three-fifths.column {
	width: 58.4%
}

.four-fifths.column {
	width: 79.2%
}

.right.column {
	text-align: right;
}

.clear:before,
.clear:after,
.clearfix:before,
.clearfix:after {
	content: ' ';
	display: table;
}

	.clear:after,
	.clearfix:after {
		clear: both;
	}

.lightbox_action.follow-me {
	background: #fff;
	width: 950px;
	padding: 0.5em 0 1em;
}

	.lightbox_action.follow-me.stuck {
		border-bottom: 1px solid #ddd;
	}

.stuck {
	position: fixed;
	top: 0;
	z-index: 10000000;
}

.related_assets ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
.related_assets ul li {
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    margin: 1em 1em 0 0;
}
.related_assets ul li img {
    display: block;
}

.user--group.cards {
	padding-bottom: var(--spacer-lg);
}
