.searchFilters {
    width: 100%;
    background-color: $white;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    padding: 1.5em;
    padding-left: 2.5em;

    @include at(md){
        width: 33%;
    }

    &.--closed{

        @include at(md){
            padding: 1.5em;
        }
    }

    &__header{
            color: $black;
            font-weight: 400;
            font-size: 1.25em;
    }

    
    &__filterGroup{
        padding: 1.5em 0;
        border-bottom: 1px solid $grey-light;
        color: $black;
    }

    &__filterGroup:last-child{
        border-bottom: none;
    }

    &__filterTitle{
        text-transform: uppercase;
        color: $black;
        padding-bottom: 1em;
        font-weight: normal;
        font-size: 1em;
    }

    &__radioButtons{
        display: flex;
        flex-direction: column;

        .switch , .radio, &.-toggles{
            padding-bottom: 1rem;
        }
    }

    &__radioButtonWrapper {
        padding-bottom: 1rem;
    }

    &__switchSlider{
        top: 2px;
    }

    &__switchText{
        padding-left: 2em;
        font-weight: 300;
        font-size: .875em;
        color: $black;
    }
}