.note {
    color: $black;
    display: inline-block;
    font-size: 14px;
    font-style: italic;
    font-weight: 300;
    margin-left: 20px;
    &.-block {
        display: block;
        margin-left: 0;
    }
}