a,
.link {
	color: $blue;
	font-weight: 700;
	text-decoration: none;
	transition: text-decoration 0.2s ease-out;
	&.-light {
		color: $white;
	}
    &.-underlined {
		font-size: 14px;
        text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
    }
    // not sure this is needed?
	img {
		border: none;
		display: block;
		width: 100%;
	}
	&:hover {
		text-decoration: underline;
	}
}

a.button {
	&:hover {
		text-decoration: none;
	}
}