.footer {
	background-color: $blue;

	&__container{
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: var(--font-size-sm);
	color: $white;
	min-height: 42px;
	}

	&__text {
		padding-right: 8em;
	}

	&__item {
		padding-right: var(--spacer-lg);
	}

	&__link {
		font-weight: 500;
	}

	&__link:hover{
		color: $turquoise;
		text-decoration: none;
	}
}