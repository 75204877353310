.notification {
    pointer-events: auto;
    margin-bottom: 1rem;
    width: 100%;
    width: 20rem;
    max-width: 24rem;
    overflow: hidden;
    border-radius: 0.5rem;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgba(113, 116, 134, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(64, 63, 63, var(--tw-text-opacity));
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
    --tw-ring-opacity: 0.05;

    &__wrapper {
        padding: 1.5rem;
        display: flex;
        align-items: flex-start;
        position: fixed;
        pointer-events: none;
        top: 200px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        z-index: 50;

        &.-toolbar {
            top: unset;
            bottom: 30px;
        }
    }

    &.--success {
        --tw-border-opacity: 1;
        border-color: rgba(157, 195, 158, var(--tw-border-opacity));
        --tw-bg-opacity: 1;
        background-color: rgba(227, 241, 228, var(--tw-bg-opacity));
    }

    &.--danger {
        --tw-border-opacity: 1;
        border-color: rgba(220, 59, 49, var(--tw-border-opacity));
        --tw-bg-opacity: 1;
        background-color: rgba(250, 233, 232, var(--tw-bg-opacity));
    }

    &.--info {
        --tw-border-opacity: 1;
        border-color: rgba(255, 185, 149, var(--tw-border-opacity));
        --tw-bg-opacity: 1;
        background-color: rgba(255, 236, 226, var(--tw-bg-opacity));
    }

    &.--primary {
        --tw-border-opacity: 1;
        border-color: rgba(140, 198, 214, var(--tw-border-opacity));
        --tw-bg-opacity: 1;
        background-color: rgba(233, 241, 244, var(--tw-bg-opacity));
    }

    &__container {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        width: 100%;
    }
    &__body {
        padding: 1em;
    }

    &__bodyWrapper {
        display: flex;
        align-items: center;
    }

    &__iconWrapper {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        align-self: center;
    }

    &__message {
        margin-left: 0.75rem;
        width: 0px;
        flex: 1 1 0%;
        padding-top: 0.125rem;
    }

    &__mainMessage {
        font-size: 0.875rem;
        margin-bottom: 0;
    }

    &__subMessage {
        margin-top: 0.25rem;
        font-size: 0.75rem;
        --tw-text-opacity: 1;
        color: rgba(107, 114, 128, var(--tw-text-opacity));
    }

    &__controls {
        margin-left: 1rem;
        display: flex;
        flex-shrink: 0;
    }

    &__close {
        display: inline-flex;
        border: none;
        background-color: transparent;
    }
}
