
html{
    --font-size: .875rem;
    --font-size-sm: .75rem;

    @include at(lg){
        --font-size: 1rem;
        --font-size-sm: .875rem;
    }

    --spacer-lg: 2em;
    --spacer-sm: 1em;
}