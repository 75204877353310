.asset {
    a {
        text-decoration: none;

        &:hover {
            // color: $blue-darker;
            text-decoration: underline;
        }
    }

    &__container {
        padding: 0px 25px;

        @include at(md) {
            padding: 0px 50px;
        }
    }

    &__tabList {
        display: flex;
        justify-content: space-between;

        @include at(lg) {
            display: block;
        }
    }

    &__tab {
        font-size: 14px;
        font-weight: 500;
    }

    &__chevron {
        font-size: 10px;
        padding-left: 10px;
        color: #6879ff;
    }

    &__plus {
        font-size: 10px;
        padding-left: 10px;
        color: #6879ff;
    }

    &__view {
        background-color: #fff;
        border-radius: 6px;
        box-shadow: 0px 0px 5px 5px #ebebeb;
        margin-top: 45px;
        padding: 0;
    }

    &__header {
        display: flex;
        font-weight: 600;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        border-bottom: 2px solid #ebebeb;
        padding: 0 1em;

        @include at(md) {
            padding: 0 50px;
        }

        @include at(lg) {
            padding: 0 0 0 25px;
        }
    }

    &__wrapper {
        padding: 40px 25px;
        
        @include at(md){
            padding: 40px 60px;
        }

        .six.column {
            width: 100%;
            margin: 0;

            @include at(md){
                width: 46%;
                margin: 0 0 0 4%;
            }
        }
    }

    &__title {
        align-items: center;
        color: $black;
        display: flex;
        padding: 15px 0;
        justify-content: space-between;
        width: 100%;

        @include at(lg) {
            width: auto;
            justify-content: unset;
        }
    }

    &__name {
        font-size: 18px;
        font-weight: 400;
    }

    &__status {
        display: inline-block;
        margin-left: 25px;
        border: 1px solid;
        padding: 2px 10px;
        font-size: 10px;
        border-radius: 12px;
        margin-top: 4px;
    }

    &__enlarge {
        cursor: pointer;
        display: block;
        margin-bottom: 1rem;
    }

    &__enlargePreview {
        background-color: #fff;
        padding: 0.4em;
        position: absolute;
        font-size: 1em;
        top: 0;
        right: 0;
    }

    &__files {
        border: 1px solid $grey-lighter;
        box-shadow: 0px 1px 3px 0px $grey-lighter;
    }

    &__preview {
        position: relative;
        text-align: center;

        img {
            max-width: 100%;
        }
    }

    &__details {
        th {
            padding: 0.5em 0;
            padding-left: 1.5em;
            font-size: inherit;
            border-bottom: 0;
            vertical-align: top;
        }

        td {
            padding: 0.5em;
            vertical-align: top;
            border-bottom: 0;

            &:nth-child(2n) {
                background-color: inherit;
            }
        }
    }

    &__download {
        padding: 1em;
        border-bottom: 1px solid $grey-lighter;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;

        .assetFile__download {
            display: inline-block;
            padding: 0 0.5em;
            border-right: 1px solid $grey-lighter;

            &:last-child {
                border-right: 0;
            }
        }
    }

    &__nav {
        font-size: 16px;
        padding: 0;
        text-align: center;
        z-index: 10;
        width: 100%;

        @include at(lg) {
            display: flex;
            width: auto;
        }
    }

    &__tab {
        padding: 20px 5px;
        position: relative;
        text-align: center;
        &:hover .asset__tabDropdown {
            visibility: visible;
            opacity: 1;
            text-align: left;
            padding-top: 20px;
            z-index: 99;
            width: auto;
            box-shadow: 0px 3px 5px -1px #ccc;
        }

        @include at(lg) {
            padding: 15px 30px;
            border-left: 2px solid #ebebeb;
        }
    }

    &__tabDropdown {
        background: #fff;
        // height: 0;
        left: 0;
        opacity: 0;
        padding: 20px 30px;
        position: absolute;
        width: 100%;
        visibility: hidden;
        // &:hover {
        //     visibility: visible;
        //     opacity: 1;
        //     display: block;
        //     text-align: left;
        //     padding-top: 20px;
        //     z-index: 99;
        //     width: auto;
        //     box-shadow: 0px 3px 5px -1px #ccc;
        // }
    }


    &__tabItem {
        border-left: 2px solid #3ca0e7;
        clear: both;
        margin-bottom: 20px;
        text-align: left;
        transition: border 0.3s ease-out;
        width: 100%;
        &:hover {
            .asset__tabLink {
                color: #3ca0e7;
                transform: translateX(5px);
            }
        }
    }

    &__tabLink {
        border-left: 2px solid transparent;
        color: #757a7e;
        display: block;
        font-size: 15px;
        font-weight: 500;
        padding-left: 10px;
        text-transform: none;
        transition: border 0.3s ease-out, color 0.3s ease-out, padding 0.3s ease-out, transform 0.3s ease-out;
        &:hover {
            color: #3ca0e7;
        }
    }

    &__caption {
        padding: 1em;
        border-bottom: 1px solid $grey-lighter;
    }

    &__usageDescription,
    &__additionalInfo {
        margin-top: 0.5em;
        padding: 1em;
        background-color: lighten($yellow, 25%);
    }

    &__workspace {
        padding: 1em;
    }

    // TO DO - update dynamic class naming

    &__active {
        background-color: $asset-active;
        border-color: darken($asset-active, 20%);
    }

    &__revisions-needed {
        background-color: $asset-revisions-needed;
    }

    &__inactive {
        background-color: $asset-deactivated;
    }

    &__pending {
        background-color: $asset-pending;
    }

    &__report-card {
        background-color: $asset-report-card;
    }

    &__hidden-from-search {
        background-color: $asset-hidden-from-search;
    }
    &__info {
        margin-top: 1.5em;

        @include at(md){
            padding: 30px 50px;
        }

        .container{
            padding: 0 25px;

            @include at(lg){
                padding: 0 50px;
            }
        }

        .six.column {
            width: 100%;
            margin: 0;

            @include at(lg){
                width: 46%;
                margin: 0 4% 0 0%;
            }
        }
    }

    &__label {
        display: block;
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 1rem;
    }
    &__history {
        background-color: $white;
        box-shadow: 0px 0px 5px 5px #ebebeb;
        padding: 1rem;
    }
    &__historyList {
        color: $black;
        font-weight: 300;
    }
}

.assetDetails {
    &__metaList {
        align-content: center;
        border-bottom: 1px solid $grey-light;
        color: $black;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        margin: 0 0 15px;
        padding-top: 1.5em;
    }

    &__metaLabel,
    &__metaValue {
        @apply break-words max-w-1/2;
        flex-basis: 50%;
        margin: 0 0 15px;
        text-align: left;
    }

    &__metaLabel {
        font-weight: 500;
        text-transform: uppercase;
    }

    &__metaValue {
        font-weight: 300;
        &.-caption {
            margin-left: 1em;
            max-width: 100%;
            font-weight: 400;
        }
    }
}

// input[type=checkbox].asset__checkbox {
//     margin-left: -15px;
//     margin-bottom: 3px;
//     width: 20px;
//     height: 20px;
// }

.rightsDocs {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
