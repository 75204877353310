.button {
	background: $blue;
	font-size: .75em;
	border: none;
	color: $white;
	cursor: pointer;
	line-height: 1.2;
	padding: 0.875em 1.5em;
	text-transform: uppercase;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-weight: 500;
	transition: all 0.2s;

	@include at(lg) {
		font-size: 1em;
	}

	&:hover{
		background: $blue-darker;
	}

	&:disabled {
		cursor: pointer;
		opacity: 65%;
	}

	&.-outline {
		background: transparent;
		border: 2px solid $white; 
		color: $white;
		padding: calc(0.875em - 2px) 1.5em;

		&.-blue{
			color: $blue;
			border-color: $blue;
		}
	}

	&.-outline.-dark {
		border-color: $blue-darker; 
		color: $blue-darker;
		font-size: .875em;
		padding: .75em 1em;
		&:hover {
			border-color: darken($blue-darker, 10%);
			color: darken($blue-darker, 10%);
		}
	}

	&.-sm {
		font-size: .5em;
		padding: .5em 1em;
	}

	&.-add,
	&.-cancel,
	&.-delete {
        background-color: transparent;
        border: none;
		color: $link;
        font-family: $family-sans-serif;
        font-weight: 400;
        font-size: 0.875rem;
    }

	&.-cancel {
		color: $grey;
		&:hover {
			color: darken($grey, 10%);
		}
	}

	&.-delete {
		display: inline-block;
		padding-left: 0;
	}
	&.-ilb {
		display: inline-block;
		padding-left: 0;
	}

	&.-add::after,
	&.-cancel::after,
	&.-delete::after {
        color: $link;
		content: "\f055";
        font-family: $icon-font;
        transition: all 0.2s;
        padding-left: var(--spacer-sm);
    }
	&.-cancel::after {
		content: "\f057";
		color: $grey;
	}
	&.-delete::after {
		content: "\f056";
	}
}