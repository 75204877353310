.searchInput{


    &__label {
		color: $black;
		text-transform: uppercase;
        padding-top: var(--spacer-lg);

		@include at(md){
            padding-top: 0;

        }
	}

    &__optionsWrapper{
        position:relative;
        padding-top: var(--spacer-lg);

        @include at(md){
            padding-top: .5em;

        }
    }


    &__options{
        position: absolute;
        background-color: $white;
        width: 100%;
        z-index: 2;
        position: absolute;
        max-height: 16rem;
        overflow-y: scroll;
        box-shadow: 0px 0px 4px $grey-light;
        list-style: none;
        padding-left: 0.75em;
    }

    &__option{
        cursor: pointer;
    }

    &__selectedSearch{
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;
        display: flex;
        max-height: 6rem;
        flex-wrap: wrap;
        gap: 0.375rem;
        overflow-y: scroll;
    }

    
    
    &__filterWrapper{
        position: relative;
        
        
        &::after {
            font-family: $icon-font;
            content: '\2b';
            transition: all .2s;
            color: $link;
            font-weight: 100;
            position: absolute;
            top: 6px;
            right: 9px;
            font-size: 1.25em;
            cursor: pointer;
        }
    }
    
    &__filter{
        border-radius: 0.25rem;
        font-size: .875em;
    }
}