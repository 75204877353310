.collapsingMenu{

    $b: &;

    &.--closed{         
        @include at(md){
            width: 15%
        }

        @include at(lg){
            width: 10%;
        }

        #{$b}__controls{
            padding-bottom: 0;
        }
    }
    
    &__controls{
        display: flex;
    }

    &__icon {
        position: relative;
    }
    &__icon::after{
        content: "\f1de";
        font-family: $icon-font;
        transition: all .2s;
        font-weight: 100;
        font-size: 1.25em;
        position: absolute;
        bottom: 2px;

        @include at(lg){
        bottom: 3px;
        }
    }
    
    &__header{
        margin-top: 0;
        margin-left: 1.5em;
        text-transform: uppercase;
        color: $black;
        font-weight: 400;
        font-size: 1.5em;

        @include at(md){
            font-size: 1.25em;
        }

        @include at(lg){
        font-size: 1.5em;
        }
    }

    &__toggle{
        background: transparent;
        border: none;
        padding: 0;
        margin-left: auto;
    }

    &__toggleIcon{
        display: block;
        transition-property: transform;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
        transform:rotate(90deg);
        padding-top: .2em;

        @include at(md){
            transform: rotate(0deg);
        }
        
        &.--rotate{
            transform:rotate(270deg);

            @include at(md){
                transform:rotate(180deg);
            }
        }
    }

    &__toggleIcon::after{
        content: "\f323";
        font-family: $icon-font;
        transition: all .2s;
        font-weight: 100;
        padding-left: .25em;
        font-size: 1.5em;
    }

    &__content{
        &.--close{
        display: none;
        }
    }

    &.--sticky {
        @include at(md) {
            position: sticky;
            top: 161px;
            max-height: 100vh;
            overflow: scroll;
        }
    }

    & > .collapsingMenu__wrapper {
        @include at(md) {
            &.--sticky {
                position: sticky;
                top: -610px;
                bottom: 0;
                margin-bottom: 135px;
            }
        }
    }
}