.utilityNav {
    $b: &;

    background-color: $blue;
    padding: 1em;

    &__loginNav {
        background-color: $blue;
        margin-bottom: 3rem;
        
        #{$b}__nav {
            position: relative;
            justify-content: flex-start;
            align-items: center;
            padding: 1rem 0;
        }

        #{$b}__list {
            display: flex;
            align-items: center;
            margin-left: auto;
        }
        &.-login {
            margin-bottom: 0;
        }
    }

    &__logo {
        color: white;
        width: 175px;
    }

    &__message {
        display: none;

        @include at(md) {
            display: block;
            text-transform: uppercase;
            padding-left: 3rem;
            margin-top: 0;
            color: #FFFFFF;
            font-weight: 400;
            font-size: 0.75em;
        }

        @include at(lg) {
            font-size: 1.125em;
        }
    }

    .drawer {
        display: flex;
        align-items: center;
        justify-content: center;

        &__contents {
            right: 0;
            z-index: 50;
            border-top-width: 0px;
            border-top-color: checked;
            --tw-bg-opacity: 1;
            background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
            padding: 1rem;
            top: 50px;
            width: 100%;
            position: absolute;
        }

        &__links {
            list-style-type: none;
            padding-left: 0;
            display: flex;
            flex-direction: column;
            gap: 1em;

            li a {
                font-family: $family-sans-serif;
                font-weight: 400;
                font-size: var(--font-size);
                color: $black;
            }

            li a:hover {
                color: $blue-darker;
            }
        }
    }

    &__nav {
        display: flex;
        justify-content: flex-end;
    }

    &__list {
        display: flex;
        align-items: center;
    }

    &__item {
        font-size: var(--font-size-sm);
        margin-left: 3em;
        color: $white;
        font-weight: 400;

        @include at(md) {
            font-size: var(--font-size-sm);
        }

        &:hover {
            color: $turquoise;
            text-decoration: none;
        }
    }

    &__content {
        display: flex;
        align-items: center;
        position: relative;
    }

    &__user {
        display: flex;
        flex-direction: column;
        color: $white;
        justify-content: center;
        font-weight: 400;
    }

    &__userDropdown {
        cursor: pointer;
        display: flex;
        grid-auto-flow: column;
        grid-column-gap: 2em;
        justify-content: center;
        align-items: center; 

        .dropdown__icon {
            transition: transform .2s ease;
        }

       &:hover {
        text-decoration: none;

        #{$b}__user li{
            color: $seafoam;
        }

        .dropdown__icon {
            transform: translateY(.25rem);
        }

        .dropdown__icon::after {
                color: $seafoam;
                transition: none;
            }
        }
    }

    &__imageWrapper {
        padding-right: 1em;
        display: flex;
        align-items: center;
    }

    &__image {
        clip-path: circle();
        height: 3.5em;
    }

    &__email {
        font-size: 0.75rem;
        font-weight: 300;
    }

    &__dropdown {
        padding-left: var(--spacer-lg);
        background: transparent;
        border: none;
        color: white;
    }
}
