.workspace {
    &__content {
        display: flex;
        justify-content: space-between;
        padding: var(--spacer-lg) 0;
        border-bottom: 1px solid $grey-light;
        flex-direction: column;

        @include at(md) {
            flex-direction: row;
        }

        .searchFilters {
            width: 100%;
            height: 100%;
            padding: 1.5em;
        }

        .collapsingMenu {
            border-radius: 0.25rem;
        }

        .collapsingMenu__controls {
            display: none;
        }
        
        &.-noBorder{
            border-bottom: none;
        }
    }

    &__textWrapper {
        padding: 2em 0;

        @include at(md) {
            padding: 0 2em;
        }
    }

    &__options {
        display: flex;
        padding-top: 2em;
    }

    &__wrapper {
        padding-left: 2em;
    }

    &__header {
        padding-bottom: 0.25em;
    }

    &__subtitle {
        font-size: 1.125em;
        margin-top: 0;
    }

    &__assetContainer {
        padding: var(--spacer-lg) 0;
    }

    &__assetHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__assets {
        display: flex;
        flex-wrap: wrap;
        row-gap: 1rem;
        margin-top: 1.5rem;
    }

    &__linkSm {
        border: none;
        background-color: transparent;
        color: $link;
        text-decoration: underline;
    }

    &__actions {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    &__filters {
        width: 100%;
    }

    &__move, &__downloadAssets {
        padding-right: 1em;

        .drawer {
            position: relative;
            
            &__contents {
                background-color: white;
                padding: 1em;
                width: 100%;
                z-index: 2;
                position: absolute;
                max-height: 16rem;
                overflow-y: scroll;
                box-shadow: 0px 0px 4px #d4d5d8;

                ul {
                    list-style-type: none;
                    padding-left: 0;
                }

                li {
                    color: $grey;
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
    }

    &__downloadAssets {

        padding-right: 0;

        .drawer {
            &__contents{
                top: 35px;
            }
        }

        a:hover,
        button:hover {
            color: $white;
        }
    }

    &__modal {
        padding: 2em;

        &--close {
            position: absolute;
            padding: 1.25rem;
            top: 0;
            right: 0;

            a:hover {
                color: $blue-darker;
            }
        }
    }

    &__modalActions {
        padding-top: 1em;
        display: flex;
        justify-content: center;

        .-outline {
            margin-left: 1em;
            border-color: $blue;
            color: $blue;

            &:hover {
                background-color: $blue-darker;
                border-color: $blue-darker;
                color: $white;
            }
        }
    }

    &__asset {
        width: 100%;

        @include at(md) {
            width: 33.333%;
        }

        @include at(lg) {
            width: 25%;
        }
    }

    &__assetHeading {
        margin-right: auto;
    }

    &__assetRemove {
        margin-right: 1.5em;
    }

    &__list {
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        flex-direction: column;
        column-gap: 4%;
        row-gap: var(--spacer-lg);

        @include at(md) {
            justify-content: flex-start;
            flex-direction: row;
            column-gap: 3%;
        }
    }

    &__text {
        margin: 0.5em 0;
    }

    &__deleteHeader {
        margin-top: 0;
        padding-top: 1em;
    }

    &__downloadContactSheet {
        padding-right: 1em;

        a:hover {
            color: $white;
        }
    }
}
