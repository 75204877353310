$black:        #262626;
$black-bis:    hsl(0, 0%, 7%);
$black-ter:    hsl(0, 0%, 14%);

$grey-darker:  hsl(0, 0%, 21%);
$grey-dark:    #585757;
$grey:         #63656A;
$grey-light:   #D4D5D8;
$grey-lighter: #F5F5F5;   

$white-ter:    hsl(0, 0%, 96%); 
$white-bis:    hsl(0, 0%, 98%);
$white:        #FFFFFF;

$orange:       hsl(14,  100%, 53%);
$yellow:       #FFCF01;
$green:        #22BB66;
$turquoise:    #37BBA2; //Biscay Green
$seafoam:      #7ACCC8;
$cyan:         hsl(204, 86%,  53%);
$coral:        #FF705F; // Living Coral
$blue:         #003A5D; // Parisian Blue
$lightblue:    #CCEEFF;
$purple:       rgb(103,54,127);
$red:          #EE3344;

// Set your brand colors
$blue: $blue; // Parisian Blue
$blue-darker: #1C1E35; // Midnight Blue
// Update Bulma's global variables

$vistana-blue: #00b4d7;

$link: $blue;
$info: $cyan;
$success: #E3F1E4;
$warning: #fffad5; // updated from #EEDD66;
$danger: #f3cbcf; // updated from $red
$dark: $grey-darker;
$text: $grey-dark;
$icon: #33ABDE;

$dark-text: $grey-lighter;

$primary: $blue;
$link-hover: $blue-darker;
// $link-hover: darken($blue-darker, 10%);

// Update some of Bulma's component variables
$body-background-color: $blue;

$asset-active: #E3F1E4;
$asset-active-dark: darken($asset-active, 5%);
$asset-active-darker: darken($asset-active, 15%);

$asset-pending: lighten($green, 10%);
$asset-pending-dark: darken($asset-pending, 5%);
$asset-pending-darker: darken($asset-pending, 15%);

$asset-revisions-needed: #fff188;
$asset-revisions-needed-dark: darken($asset-revisions-needed, 5%);
$asset-revisions-needed-darker: darken($asset-revisions-needed, 20%);

$asset-deactivated: #f4e5e5;
$asset-deactivated-dark: darken($asset-deactivated, 5%);
$asset-deactivated-darker: darken($asset-deactivated, 15%);

$asset-report-card: $asset-deactivated;
$asset-report-card-dark: darken($asset-report-card, 5%);
$asset-report-card-darker: darken($asset-report-card, 15%);

$asset-hidden-from-search: lighten($purple, 50%);
$asset-hidden-from-search-dark: lighten($purple, 25%);
$asset-hidden-from-search-darker: $purple;
