$sizes: (
  '0': 0, '1': 0.25rem, '2': 0.5rem, '3': 0.75rem,
  '4': 1rem, '5': 1.25rem, '6': 1.5rem, '7': 1.75rem,
  '8': 2rem, '9': 2.25rem, '10': 2.5rem, '11': 2.75rem,
  '12': 3rem,
);

@each $size-name, $size-value in $sizes {
    .p-#{$size-name} {
        padding: $size-value;
    }
    .pt-#{$size-name} {
        padding-top: $size-value;
    }
    .pb-#{$size-name} {
        padding-bottom: $size-value;
    }
    .pl-#{$size-name} {
        padding-left: $size-value;
    }
    .pr-#{$size-name} {
        padding-right: $size-value;
    }
    .px-#{$size-name} {
        padding-left: $size-value;
        padding-right: $size-value;
    }
    .py-#{$size-name} {
        padding-top: $size-value;
        padding-bottom: $size-value;
    }
}

@each $size-name, $size-value in $sizes {
    .m-#{$size-name} {
        margin: $size-value;
    }
    .mt-#{$size-name} {
        margin-top: $size-value;
    }
    .mb-#{$size-name} {
        margin-bottom: $size-value;
    }
    .ml-#{$size-name} {
        margin-left: $size-value;
    }
    .mr-#{$size-name} {
        margin-right: $size-value;
    }
    .mx-#{$size-name} {
        margin-left: $size-value;
        margin-right: $size-value;
    }
    .my-#{$size-name} {
        margin-top: $size-value;
        margin-bottom: $size-value;
    }
}