.list {
    $b: &;

    &.-inline {
        align-content: center;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        #{$b}__item {
            align-items: center;
            display: flex;
            padding: 0 0.25rem;
            width: 100%;

            @include at(md){
                width: 48%;
            }
        }
    }

    &__item {
        margin-bottom: 1rem;
    }
}