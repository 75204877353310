.linkNav {
    $b: &;
    &__link {
        color: $black;
    }
    &__link:hover,
    &__link:hover .linkNav__icon {
        color: $blue-darker;
    }

    &__item {
        padding: 0.5rem 0.5rem;

        @include at(md){
            padding: 0.5rem 1rem;
        }
    }

    &__links {
        display: flex;
        justify-content: space-evenly;
        @include at (md){
            display: block;
        }
    }
    &__link {
        display: flex;
    }

    &__links {
        list-style-type: none;
        padding: 0;
    }

    &__text {
        display: none;
        font-family: $family-sans-serif;
        font-size: 1.1em;
        @include at (md){
            display: block;
        }
    }
    
    &__icon {
        color: $black;
        transition: color 0.2s ease-out;
        &::after {
            font-family: $icon-font;
            font-weight: 400;
            font-size: 1.1em;

            @include at(md){
                padding-right: var(--spacer-sm);
            }
        }

        &.-all::after {
            content: "\f03a";
        }
        
        &.-new::after {
            content: "\f067";
        }
        
        &.-view::after {
            content: "\f06e";
        }
        
        &.-email::after {
            content: "\f0e0";
        }

        &.-send::after {
        content: "\f064";
        }
        
        &.-delete::after {
            content: "\f1f8";
        }
        
        &.-copy::after {
            content: "\f0c5";
        }
        
        &.-edit::after {
            content: "\f044";
        }
    }
}