.vs {

    &--single{
        padding-left: .5em;
    }
    &__clear, 
    &__search{
        display: none;
    }

    &__selected {
        font-weight: 700;
    }

    &__dropdown-toggle{
        border: none!important;
        background: transparent!important;
        padding-bottom: 0px!important;
    }

    &__open-indicator{
        fill: $blue-darker!important;
    }
}