.primaryNav {
    $b: &;

    background-color: $grey-lighter;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    @include at(md) {
        flex-direction: row;
    }

    &__logoWrapper {
        width: 175px;
        padding-top: 2em;

        @include at(md) {
            padding-top: 0;
        }
    }

    &__logo {
        width: 100%;
        color: $blue;
    }

    &__nav {
        padding: 2em 0px 2em;

        @include at(md) {
            padding: 2.5em 0px 2.5em;
        }
    }

    &__list {
        text-align: center;
        display: flex;
    }

    &__item {
        position: relative;
        display: flex;
        justify-content: center;
        width: 115px;

        @include at(md) {
            width: 130px;
        }

        @include at(lg) {
            width: 175px;
        }

        &:hover {

            &::after {
                content: '';
                border-color: $coral;
                border-bottom-width: 2px;
                border-bottom-style: solid;
                display: block;
                position: absolute;
                left: 0px;
                right: 0;
                bottom: -2em;

                @include at(md) {
                    bottom: -2.5em;
                }

                @include at(lg) {
                }
            }
        }

        &.-active {
            
            #{$b}__link {
                font-weight: 600;
            }

            &::after {
                content: '';
                border-color: $coral;
                border-bottom-width: 7px;
                border-bottom-style: solid;
                display: block;
                position: absolute;
                left: 0px;
                right: 0;
                bottom: -2.0em;

                @include at(md) {
                    bottom: -2.5em;
                }

                @include at(lg) {
                }
            }
        }
    }

    &__link {
        font-weight: 500;
        font-size: var(--font-size-lg);
        color: $black;
        text-transform: uppercase;

        &:hover {
            text-decoration: none;
        }

        @include at(lg) {
            font-size: 1.125em;
        }
    }

    &__iconHome::after,
    &__iconSearch::after,
    &__iconWork::after {
        color: $link;
        font-family: $icon-font;
        transition: all 0.2s;
        font-weight: 100;
        padding-right: var(--spacer-sm);
        font-size: var(--font-size);
        display: none;

        @include at(md) {
            display: inline-block;
        }
    }

    &__iconHome::after {
        content: '\f015';
    }

    &__iconSearch::after {
        content: '\f002';
    }

    &__iconWork::after {
        content: '\f0db';
    }
}
