.assetListing{
    display: flex;
    flex-wrap: wrap;
    row-gap: 1em;

    &__contentRow{
        display: flex;
        width: 100%;
        flex-direction: column;

        @include at(lg){
            flex-direction: row;
        }
    }

    &__content{
        width: 100%;
        padding-bottom: 0em;
        margin-bottom: 1rem;
    
        @include at (lg){
            width: 33.333%;
        }
    }
    &__brandGuide{
        width: 100%;
        padding-bottom: 0em;
        margin-bottom: 1rem;
    
        @include at (lg){
            width: 25%;
        }
    }

    .full-width {
        margin: 1rem 0;
        width: 100%;
    }
    .list-format-toggle {
        margin: 1rem 0 0 0;
        text-align: right;

        @include at (lg){
            margin-top: -40px;
        }
        
        i {
            cursor: pointer;
            font-size: 2rem;
            margin-left: 1rem;

            &.inactive {
                color:#BBBBBB;
            }
            &:hover {
                color:#4582D0;
            }
        }
    }
}