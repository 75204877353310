.workspaces {

    &__cardWrapper{
        padding: 2em 0;

        &.-noPaddingTop {
            padding-top: 0
        }
    }

    &__header {
        border-bottom: 1px solid #d4d5d8;
        padding: 0 0px 10px;
    }

    &__title{
        color: #757a7e;
        padding-bottom: 0.5em;
    }

    &__cards{
        padding: var(--spacer-lg) 0;
    }

    &__form{
        padding-top: var(--spacer-lg);
    }

    &__list {
        display: flex;
        justify-content: space-between;
        padding: 0 0 var(--spacer-sm);
    }

    &__link{
        font-size: 1.1em;
    }
}
