@keyframes load {
    from {
        left: -150px;
    }
    to   {
        left: 100%;
    }
}
.news{
    padding-bottom: var(--spacer-lg);

    &__header{
        padding-bottom: var(--spacer-sm);
    }

    &__cards{
        padding-bottom: var(--spacer-lg);

        .card{
            display: flex;
            flex-direction: column;
            margin-bottom: 1em;
    
            @include at(md){
                width: 48%;
            }

            @include at(lg){
                width: 32%;
            }

            &__image{
                height: auto;
                position: unset;
            }

            &__contentWrapper{
                height: 100%;
            }

            &__title a{
                &::before{
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    bottom: 0px;
                    left: 0px;
                    height: 100%;
                    width: 100%;
                    cursor: pointer;
                    content: "";
                }
            }


            &__cta p{
                margin-bottom: 0;
            }


        }

        .placeholder-card {
            box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
            border-radius: 4px;
            min-height: 212.859px;
            position: relative;
            overflow: hidden;

            & > span {
                color: transparent;
            }
            
            &::before {
                content: '';
                display: block;
                position: absolute;
                left: -150px;
                top: 0;
                height: 100%;
                width: 100%;
                background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
                animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
            }
        }
    }

    &__list{
        list-style: none;
		padding: 0;
        padding-bottom: var(--spacer-lg);
    }

    &__listItem{
        display: flex;
        align-items: center;
    }

    &__badge{
        background: $blue;
        color: #fff;
        border-radius: 5em;
        line-height: 1em;
        font-size: var(--font-size-sm);
        font-weight: bold;
        height: 3em;
        width: 3em;
        display: inline-block;
        text-align: center;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        text-transform: uppercase;
        z-index: 10000;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__story{
        margin-left: 1em;
    }
}