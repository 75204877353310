.relatedAssets {
    &--header h2 {
        margin-right: 0.5rem;
        display: inline-block;
        width: auto;
        margin-bottom: 1.5em;
    }

    &--assets {
        display: flex;
        flex-wrap: wrap;
    }

    .relatedAsset {
        margin-right: 2rem;
        margin-bottom: 2rem;
        height: 13rem;
        width: 13rem;
        cursor: pointer;
        background-size: cover;
        border: 1px solid #ebebeb;
        border-radius: 6px;

        &--details {
            height: 100%;
            width: 100%;
            // #0096d6
            background-color: rgba(0, 150, 214, .6);
            padding: 1rem;
            font-size: .875rem;
            color: rgba(255, 255, 255, .6);
            opacity: 0;
            transition-property: opacity;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 150ms;
            border-radius: 6px;
        }
    
        &--details:hover {
            opacity: 1;
            box-shadow: 0px 0px 5px 5px #ebebeb;
        }
    
        &--type {
            margin-bottom: 0.75rem;
            display: inline-block;
            font-weight: 600;
            text-transform: uppercase;
        }
    }
}


// .related-assets {
//     margin-top: 15px;
//   }
//   .related-assets__list {
//     list-style-type: none;
//     padding: 0;
//     margin: 0;
//     display: flex;
//     flex-flow: row wrap;
//     align-items: center;
//   }
//   .related-assets__asset_inactive {
//     background-color: #FEE;
//   }
//   .related-assets__asset_revisions-needed {
//     background-color: #fffad1;
//   }
//   .related-assets__asset, .related-assets__asset:link {
//     display: inline-block;
//     margin: 10px;
//     padding: 10px;
//     color: #333;
//     text-decoration: none;
//   }
//   .related-assets__asset:hover {
//     background-color: #f2f2f2;
//   }
//   .related-assets__asset_inactive:hover {
//     background-color: #ffd5d5;
//   }
//   .related-assets__asset_revisions-needed:hover {
//     background-color: #fff7b8;
//   }
//   .related-assets__asset-wrapper {
//     width: 25%;
//   }
//   .related-assets__asset-thumbnail {
//     max-width: 100%;
//   }
//   .related-assets__asset h3 {
//     font-size: 14px;
//   }
//   .related-assets__asset-caption {
//     margin: 0;
//   }
//   .related-assets__asset-filename {
//     margin: 0;
//     margin-top: 10px;
//     font-style: italic;
//   }