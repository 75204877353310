.pill {
    display: inline-flex;
    align-items: center;
    border: 1px solid $blue-darker;
    border-radius: 3px;
    padding: 0.125rem 0.5rem;
    padding-right: 1.1rem;
    font-size: 0.675rem;
    color: $black;
    background-color: #e7f1f4;
    position: relative;

    &.--success {
        background-color: $success;
        border: 1px solid #9dc39e;
    }

    &.--yellow {
        background-color: $warning;
        border: 1px solid rgb(209, 209, 82);
    }

    &.--danger {
        background-color: $danger;
        border: 1px solid rgb(205, 98, 98);
    }

    &.--pending {
        background-color: $lightblue;
        border: 1px solid rgb(87, 154, 195);
    }

    &.-rounded {
        border-radius: 9999px;
        padding-right: .5rem;
    }

    .exit{
        position: unset;
    }

    .exit::after {
        font-size: 0.75rem;
        position: absolute;
        top: 2.5px;
        right: 6px;
    }
}
