.assetSearch {
	box-sizing: border-box;
	background: $blue url('/sup/img/homepage/search-background.jpg') no-repeat scroll 50% 50%;
	color: #fff;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	position: relative;
	box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
	min-height: 800px;
	background-size: cover;
	position: relative;

	@include at(md) {
		min-height: 350px;
	}

	&::before {
		content: '';
		background: linear-gradient(transparent 50%, #070b1d);
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0px;
		top: 0px;

		@include at(md) {
			align-items: center;
		}
	}

	&__container {
		display: flex;
		justify-content: center;
		padding-top: 4.5em;
		padding-bottom: 2em;

		@include at(md) {
			align-items: center;
		}
	}

	.drawer {
		position: relative;

		&__contents {
			width: 100%;
			position: absolute;
		}
	}

	&__asset-lookup {
		width: 100%;
		z-index: 3;
	}

	&__panel {
		background: $white;
		grid-column: 1 / span 10;
		grid-row: 2;
		min-height: 150px;
		box-sizing: border-box;
		border-radius: 0.25rem;
	}

	&__actions {
		margin-top: var(--spacer-sm);
	}

	&__filters {
		display: grid;
		grid-template-columns: repeat(1, minmax(0, 1fr));
		padding: 1.25em var(--spacer-sm);
		box-sizing: border-box;
		height: 100%;

		@include at(md) {
			grid-template-columns: repeat(4, 1fr);
		}
	}

	&__filter:nth-child(1) {
		@include at(md) {
			padding-left: 0px;
			border-left: none;
		}
	}

	&__filter {
		padding-top: var(--spacer-sm);

		@include at(md) {
			padding-right: 25px;
			padding-left: 25px;
			border-left: 1.25px solid $grey-light;
		}
	}

	&__label {
		color: $black;
		text-transform: uppercase;
	}

	&__actions {
		grid-row: 3;
		grid-column: 1 / span 10;
		display: flex;
		justify-content: space-between;
		flex-direction: column-reverse;
		align-items: center;

		@include at(md) {
			flex-direction: row;
		}
	}

	&__recent {
		grid-column: 1;
		width: fit-content;
		margin-right: auto;
	}

	&__clear {
		color: $white;
		margin: 1em;

		@include at(md) {
			margin-left: auto;
			margin-right: var(--spacer-lg);
		}
	}

	&__form {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 1.5em;
		width: 100%;

		@include at(md) {
			margin-bottom: 0;
			width: 50%;
			justify-content: flex-end;
		}
	}
	&__button {
		font-size: 0.8em;
		text-transform: none;
		width: 100%;
		font-weight: 600;
		display: flex;
		align-items: center;
	}

	&__advanced {
		margin-right: var(--spacer-sm);

		.-outline {
			background-color: rgba(38, 38, 38, 0.25);
			white-space: nowrap;
		}
	}

	&__placeholder {
		font-size: 0.875rem;
		margin-top: 0.625em;
	}

	&--filterTitle {
		padding: 1em 0;
	}

	&__radioButtons {
		display: flex;
		flex-direction: column;
	}
}
