.login {
    box-sizing: border-box;
    background: $blue url('/sup/img/homepage/search-background.jpg') no-repeat scroll 50% 50%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background-size: cover;
    min-height: 100vh;

    &__body {
        display: flex;
        flex-direction: column;
        padding-top: 4em;

        @include at(lg) {
            flex-direction: row;
        }
    }

    &__loginCard,
    &__createCard {
        padding: 1.5em;
        width: 100%;
        box-shadow: none;

        @include at(lg) {
            width: 48%;
            padding: 3.25em;
        }
    }

    &__loginCard {
        margin-bottom: 3em;

        @include at(lg) {
            margin-right: 3em;
            margin-bottom: 0;
        }
    }

    &__form {
        @include at(lg) {
            width: 75%;
        }
    }

    &__createCard {
        align-self: start;
    }

    &__heading {
        margin-bottom: 0.5em;
        font-family: $family-sans-serif;
        text-transform: none;
        color: $black;
    }

    &__text {
        font-weight: 300;
    }

    &__createButton {
        margin-top: 1em;
    }

    &__actions {
        display: flex;
        justify-content: space-between;

        a {
            text-decoration: underline;
            font-size: var(--font-size-sm);
        }
    }

    &__label {
        font-weight: bold;
    }

    &__message {
        position: absolute;
        z-index: 1001;
        top: 75px;
        right: 50px;
        left: 50px;
    }
}
